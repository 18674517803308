import { Icon } from "#components";

export default defineComponent({
  name: "Info",
  props: {
    text: { type: String },
  },
  render() {
    return h("span", { class: "flex-align" }, [
      h(Icon, { class: "mr-1", name: "emojione:light-bulb" }),
      this.$slots.default ? this.$slots.default() : this.text,
    ]);
  },
});
